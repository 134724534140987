import { defineStore } from 'pinia';
import { UserDTO } from '~/dto/UserDTO';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

export const useUserStore = defineStore('userStore', {
  state: () => ({
    user: new UserDTO(),  // The user object contains firstName, lastName, etc.
    epassBuyerIdUser: 0,
  }),
  actions: {
    async fetchUser(data: any) {
      const userFetch = await data;
      console.log('test',userFetch);
      this.user = new UserDTO(userFetch.data);
    },
    setUser(data: any) {
      this.user = new UserDTO(data); 
    },
  },
  persist: true,
});

  
